import styled from "styled-components"

export const ChatHeader = styled.header`
    display: grid;
    min-height: 50px;
    max-height: 50px;
    background-color: #e1e1e4;
    align-items: center;
    /* grid-template-columns: 0fr minmax(0, 2fr) 1fr; */
    justify-content: center;

    position: sticky;
    top: 0;

    /* opacity : 0; */
    
    width: 100vw;
`

export const ChatTitle = styled.div`
    width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        margin-right: 0.2rem;
        border-radius: 50%;
    }
    span {
        font-family: "Roboto", Arial, Helvetica, sans-serif;
        font-size: 1.7rem;
        font-weight: bold;
        margin-left: 0.2rem;
    }
`

export const ColumnScroll = styled.div`
    /* width: min(48rem, 100%); */
    flex: 1 1;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 90vh;

    padding-left: 10px;
    padding-right: 10px;
`

export const ColumnSection = styled.div`
    width: 100%;
    flex: 1 1;
    align-self: center;
    display: flex;
    flex-direction: column;

    padding-bottom: 10px;
    padding-top: 10px;
`

export const ChatBar = styled.footer`
    position: fixed;
    bottom: 0;
    /* padding: 0.5rem 0.5rem 1rem; */
    width: 100%;
    overflow-anchor: none;
    background: white;
`

export const MessageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1vh;
`

export const MessageBubble = styled.div`
    display: inline;
    flex-direction: column;
    overflow-x: hidden;
    padding: .7rem;
    border-radius: 12px;
    word-break: break-word;
    box-sizing: border-box;
    max-width: 70%;
`

export const MessageOption = styled.button`
    background-color: transparent;
    border: 1.5px solid #000;
    border-radius: 10px;
    width:100%;
    text-align:left;
    padding: 0.4rem;
    margin-bottom: 0.5rem;
    color: #000;
    font-weight: bold;
    font-family: "Roboto"
`

export const ImageOption = styled.button`
    background-color: transparent;
    border: 1.5px solid #000;
    border-radius: 10px;
    width:100%;
    text-align:left;
    padding: 0.4rem;
    margin-top: 0.5rem;
    color: #000;
    font-weight: bold;
`

export const MessageContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    overflow-x: auto;

    p {
        font-family: "Lato", Verdana, sans-serif;
        font-size: 1rem;
        font-weight: 500;
        color: white;
        margin: 0
    }
`

// export const MessageComponent = () => {
//     return (
//         <MessageWrapper className="user-msg">
//             <MessageBubble className="bubble">
//                 <MessageContent>
//                     <p>Hello</p>
//                 </MessageContent>
//             </MessageBubble>
//         </MessageWrapper>
//     )
// }