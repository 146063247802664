// import logo from './logo.svg';
// import { useEffect } from "react";

import "./css/Home.css";
// import { ChatAreaComp } from './components/ChatElements';
import { ChatApp } from './components/chat/index'

// import AdminApp from './components/Admin'

import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      {/* <NavbarBootstrap /> */}
      <Switch>

        <Route exact path="/" element={<ChatApp />} />
      </Switch>
    </Router>
    
  );
}

export default App;
