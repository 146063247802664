import { useRef } from "react";
// import styled from "styled-components"

import { ImageOption } from "./ColumnChat";

// import "./styles.css";
export const FileUploader = ({ handleFile }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);

    event.target.parentElement.remove()
  };
  return (
    <div style={{width:"100%"}}>
      <ImageOption className="button-upload" onClick={handleClick}>
        Upload an image
      </ImageOption>
      <input
        type="file"
        accept="image/png, image/jpeg" 
        capture="environment"

        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </div>
  );
};
